<template>
  <div class="page">
    <div class="topView">速绿品牌已与近300位明星合作，更入驻包含电竞类赛事、体育赛事、动漫展、音乐节等活动</div>
    <div class="header">
      <div class="headerView">
        <div class="titleView">
          <span style="font-size:32px;">公司动态</span>
          <img style="width:1px;height:20px;background:#2EB94F;margin:0 10px;">
          <span style="color:#E2E2E2;">DYANMICS</span>
        </div>
        <div class="headerNavi">
          <div
            class="pointer"
            v-for="item in navi"
            :key="item.code"
            :style="{color:item.code==codeSel?'#2EB94F':'#000'}"
            @click="naviTouch(item.code)">
            {{item.title}}
          </div>
        </div>
      </div>
    </div>
    <div class="contentView">
      <img class="coverItem pointer" 
        :style="{marginRight:index%4!=3?'20px':'0'}"
        v-for="(item,index) in covers"
        :key="index+100"
        :src="item.img"
        @click="coverTouch(item.url)"/>
    </div>
    <div style="padding-bottom:50px;">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size='12'
        :total="urls[codeSel].length"
        :current-page="currentPage"
        @current-change="pageChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {Pagination} from 'element-ui'
Vue.use(Pagination)
export default {
  data () {
    return {
      codeSel: '',
      covers:[],
      navi: [{title:'公司动态',code:'trends'},{title:'品牌合作',code:'collaborate'},{title:'速绿-永不止步',code:'sulv'}],
      currentPage: 1,
      urls: {
        trends: [
          {img:'14',url:'https://www.sohu.com/a/492333367_120925868'},
          {img:'15',url:'https://www.sohu.com/a/492333273_120925868'},
          {img:'17',url:'http://www.yidianzixun.com/article/0XTqRVOj/amp'},
          {img:'18',url:'https://mp.weixin.qq.com/s/Sg7ZY7HnWPRZhd-ML6HdDw'},
          {img:'19',url:'https://mp.weixin.qq.com/s/gLxIF8qvJc1_m613eLvnJA'},
          {img:'20',url:'http://k.sina.com.cn/article_6542138861_185f11ded00100xtgx.html'},
          {img:'22',url:'https://3g.k.sohu.com/t/n549528989'},
          {img:'23',url:'https://t.cj.sina.com.cn/articles/view/6521331144/184b39dc800100te90?from=edu'},
          {img:'24',url:'https://t.cj.sina.com.cn/articles/view/5647528159/1509e70df001014ibt?from=tech'},
          {img:'25',url:'http://k.sina.com.cn/article_6521331144_184b39dc800100tcwj.html'},
          {img:'26',url:'https://view.inews.qq.com/a/20210721A0DGLM00'},
          {img:'27',url:'https://mp.weixin.qq.com/s/0eBEgCraFEm5hP3HWic9fQ'},
          {img:'28',url:'https://www.donews.com/news/detail/4/3161708.html'},
          {img:'29',url:'https://mp.weixin.qq.com/s/uVTKmlL5lDaQmDDpaBemKA'},
          {img:'30',url:'https://www.sohu.com/a/475137783_120925823'},

          {img:'0',url:'http://www.techweb.com.cn/news/2021-01-15/2821431.shtml'},
          {img:'1',url:'https://k.sina.com.cn/article_6627250585_18b03d19900100v0la.html?kdurlshow=1&wm=3049_0047&from=travel'},
          {img:'2',url:'https://www.sohu.com/a/472693256_121013996'},
          {img:'3',url:'https://www.sohu.com/a/469106315_120777541'},
          {img:'4',url:'http://www.china-syw.cn/shenghuo/1004874.html'},
          {img:'5',url:'https://page.om.qq.com/page/OA3NcxjjFIBbSSSmRLA4zEHA0'},
          {img:'6',url:'https://mbd.baidu.com/newspage/data/landingshare?preview=1&pageType=1&isBdboxFrom=1&context=%7B%22nid%22%3A%22news_9834517940030206503%22%2C%22sourceFrom%22%3A%22bjh%22%7D'},
          {img:'7',url:'https://www.sohu.com/a/461093199_120925868'},
          {img:'8',url:'https://t.cj.sina.com.cn/articles/view/6634340124/18b6fff1c00100tat2'},
          {img:'9',url:'https://page.om.qq.com/page/OYZyTAj-XdU85JmnRGi5gRhQ0'},
          {img:'10',url:'https://mp.weixin.qq.com/s/IYvvgyWalVg1VMwzvoP98w'},
          {img:'11',url:'https://mp.weixin.qq.com/s/TVnUPL0yHYXYX7NKLU5ABQ'},
          {img:'12',url:'https://mp.weixin.qq.com/s/N4cWGuwuTfTq1ZnpeE35IA'},
        ],
        collaborate: [
          {img:'62',url:'https://www.sohu.com/a/495992992_515745'},
          {img:'63',url:'http://www.hqkjrb.com.cn/caijing/2033395.html'},
          {img:'64',url:'https://www.sohu.com/a/483195444_120777541'},

          {img:'24',url:'https://k.sina.com.cn/article_6634889548_18b78614c00100wq34.html?kdurlshow=1&wm=3049_0047&from=ent&subch=film&sudaref=www.meiticaigou.com&display=0&retcode=0'},
          {img:'25',url:'https://k.sina.com.cn/article_6492883495_183018a2700100x37l.html?kdurlshow=1&wm=3049_0047&from=tech&sudaref=www.meiticaigou.com&display=0&retcode=0'},
          {img:'26',url:'https://k.sina.cn/article_6532704997_185612ae500100wgmz.html?&kdurlshow=1&sinawapsharesource=newsapp&wm=3200_0024'},
          {img:'27',url:'http://www.buku66.com/caijing/shh/202110139395.html'},
          {img:'28',url:'https://www.sohu.com/a/494824584_120140176'},
          {img:'29',url:'http://www.zgitbd.com.cn/shenghuo/2033389.html'},
          {img:'30',url:'http://www.beijingshichuang.cn/shenghuo/2033393.html'},
          {img:'31',url:'https://www.sohu.com/a/490683362_120777541'},
          {img:'32',url:'https://www.sohu.com/a/490419146_120953810'},
          {img:'33',url:'https://www.sohu.com/a/489049111_120982345'},
          {img:'34',url:'https://www.sohu.com/a/488509132_120777541'},
          {img:'35',url:'https://www.sohu.com/a/488489167_120982345'},
          {img:'36',url:'https://www.sohu.com/a/488487415_120982345'},
          {img:'37',url:'https://t.cj.sina.com.cn/articles/view/6507338126/183de198e00100u0gb?sudaref=www.baidu.com&display=0&retcode=0'},
          {img:'38',url:'http://k.sina.com.cn/article_6672099868_18db02a1c00101a8n1.html'},
          {img:'39',url:'https://www.sohu.com/a/485994790_120982345'},
          {img:'40',url:'http://k.sina.com.cn/article_6672099868_18db02a1c00101a2h9.html'},
          {img:'41',url:'https://www.sohu.com/a/485083981_120927380'},
          {img:'42',url:'http://k.sina.com.cn/article_6492655501_182fe0f8d00100wi8s.html'},
          {img:'43',url:'https://www.sohu.com/a/484474156_212430'},
          {img:'44',url:'https://www.163.com/dy/article/GHOTTHEF0552G5ZL.html'},
          {img:'45',url:'https://www.sohu.com/a/484313063_120926408'},
          {img:'46',url:'http://k.sina.com.cn/article_6507338126_183de198e00100tt01.html'},
          {img:'47',url:'https://www.sohu.com/a/481813502_120834059'},
          {img:'48',url:'http://www.itjike.com.cn/keji/1967717.html'},
          {img:'49',url:'http://www.kejizhoukan.com/keji/1966243.html'},
          {img:'50',url:'http://k.sina.com.cn/article_6672099868_18db02a1c001019juz.html'},
          {img:'51',url:'http://www.zhirongw.com.cn/keji/1964406.html'},
          {img:'52',url:'http://k.sina.com.cn/article_6507420308_183df5a94001024ock.html'},
          {img:'53',url:'https://t.cj.sina.com.cn/articles/view/6521331144/184b39dc800100t4yk?sudaref=www.baidu.com&display=0&retcode=0'},
          {img:'54',url:'https://www.sohu.com/a/478959528_120777541'},
          {img:'55',url:'https://www.sohu.com/a/480436133_120777541'},
          {img:'56',url:'https://t.cj.sina.com.cn/articles/view/6672099868/18db02a1c0010197fq?from=tech'},
          {img:'57',url:'https://t.cj.sina.com.cn/articles/view/6507338126/183de198e00100tc7p?from=tech'},
          {img:'58',url:'https://cpu.baidu.com/pc/1022/275122716/detail/53513270115523080/news?chk=1'},
          {img:'59',url:'http://k.sina.com.cn/article_6672099868_18db02a1c0010192b3.html'},
          {img:'60',url:'https://www.sohu.com/a/476337352_120925823'},
          {img:'61',url:'https://t.cj.sina.com.cn/articles/view/6521331144/184b39dc800100spjs?from=tech'},

          {img:'0',url:'https://www.sohu.com/a/471838268_515745'},
          {img:'1',url:'https://www.yidianzixun.com/article/0SyGC8eA'},
          {img:'2',url:'https://k.sina.cn/article_6600255725_18967e8ed00100sd0r.html?kdurlshow=1&mod=wpage&r=0&tr=381'},
          {img:'3',url:'http://www.loveesport.cn/roll/60831.html'},
          {img:'4',url:'https://baijiahao.baidu.com/s?id=1702050996743473997&wfr=spider&for=pc'},
          {img:'5',url:'https://www.sohu.com/a/456255433_515745'},
          {img:'6',url:'https://new.qq.com/omn/20210318/20210318A0CDVT00.html'},
          {img:'7',url:'https://k.sina.cn/article_6507338126_183de198e00100rnvz.html?from=tech&kdurlshow=1&sinawapsharesource=newsapp&wm=3200_0024'},
          {img:'8',url:'https://page.om.qq.com/page/OJ8opza9wXS6I0LdMNfQl0kw0'},
          {img:'9',url:'http://www.itjike.com.cn/yule/1833647.html'},
          {img:'10',url:'http://www.facenews.cn/v-1-347523.html'},
          {img:'11',url:'https://page.om.qq.com/page/O4z2HHvntBhsdZAP9NosxE0g0'},
          {img:'12',url:'https://new.qq.com/omn/20210525/20210525A02KVD00.html'},
          {img:'13',url:'https://new.qq.com/omn/20210525/20210525A031ZL00.html'},
          {img:'14',url:'https://page.om.qq.com/page/OCldHENLpJv3aA_Sy-LdSmWA0'},
          {img:'15',url:'https://www.sohu.com/a/468483945_515745'},
          {img:'16',url:'http://www.facenews.cn/v-1-357641.html'},
          {img:'17',url:'https://xw.qq.com/cmsid/20210609A054YD00'},
          {img:'18',url:'https://new.qq.com/omn/20210611/20210611A05X4B00.html'},
          {img:'19',url:'http://www.kejizhoukan.com/shenghuo/1909249.html'},
          {img:'20',url:'https://page.om.qq.com/page/OxcuIWEm-7CWaFNR54XZRnIA0'},
          {img:'21',url:'http://www.zgkjzk.com.cn/yule/1912565.html'},
          {img:'22',url:'https://www.donews.com/news/detail/4/3132008.html'},
          {img:'23',url:'https://mp.weixin.qq.com/s/FgGGW89PmRNGX_2jhYmlvA'},
        ],
        sulv: [
          {img:'7',url:'https://mp.weixin.qq.com/s/JfuMH0NodixOQN_COUiWsQ'},
          {img:'8',url:'https://mp.weixin.qq.com/s/hAVljqUuAqpVOBuOmSE3wQ'},
          {img:'9',url:'https://mp.weixin.qq.com/s/cPAjb8N6JSLyRDfbLBjAVw'},
          
          {img:'0',url:'https://mp.weixin.qq.com/s/bIjar1x48-Z2gzEouDHoZA'},
          {img:'1',url:'https://mp.weixin.qq.com/s/YPdGzzLYC4mw__LvZAn6zg'},
          {img:'2',url:'https://mp.weixin.qq.com/s/90vUe2m7OZGtX8Z_k9_w2A'},
          {img:'3',url:'https://mp.weixin.qq.com/s/pG28ZatlEziDM0LQl8UP7w'},
          {img:'4',url:'https://mp.weixin.qq.com/s/oHpBLNDeoiXoeeBu6Axgmg'},
          {img:'5',url:'https://mp.weixin.qq.com/s/I6sPW3VrcCwB1hAAzShGbw'},
          {img:'6',url:'https://mp.weixin.qq.com/s/S0aPkeGXyrn36WaqMuL84w'},
        ]
      }
    };
  },
  components: {},
  mounted () {
    this.naviTouch(this.$route.query.code || 'trends');
  },
  methods: {
    naviTouch(code){
      this.codeSel = code;
      this.currentPage = 1;
      this.getCovers();
    },
    coverTouch(url){
      window.open(url,"_blank")
    },
    pageChange(page){
      this.currentPage = page;
      this.getCovers();
    },
    getCovers(){
      let covers = [];
      let start = (this.currentPage-1)*12;
      let newUrls = this.urls[this.codeSel].slice(start,start+12)
      newUrls.map((item)=>{
        covers.push({
          url: item.url,
          img: require(`../assets/img/${this.codeSel}_${item.img}.jpg`)
        })
      })
      this.covers = covers
    },
  }
}
</script>

<style lang='scss' scoped>

.pointer{
  cursor: pointer;
}

.page{
  width: 100%;
}
.topView{
  width: 100%;
  height: 80px;
  line-height: 80px;
  background: #2EB94F;
  color: white;
  font-size: 22px;
  font-weight: 500;
}
.header{
  border-bottom: 1px solid #2EB94F;
}
.headerView{
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}
.titleView{
  font-size: 28px;
  text-align: left;
  margin: 40px 0;
  padding-left: calc((100% - 1200px) / 2);
}
.headerNavi{
  display: flex;
  justify-content: space-between;
  div{
    margin-left: 20px;
  }
}
.contentView{
  width: 1210px;
  margin: 0 auto;
  padding: 40px 0;
  display: flex;
  flex-wrap: wrap;
  .coverItem{
    width: 286px;
    height: 421px;
    margin-bottom: 25px;
  }
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #2EB94F;
}

/deep/ .el-pagination.is-background .el-pager li:not(.active):hover{
  color: #2EB94F;
}

</style>